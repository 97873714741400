import Api from '../../services/Api'
import useToastify from '../ui/useToastify'
import { useState } from 'react'
import { forgotPasswordURL, resetPasswordURL } from '../../ApiEndpoints'

function useGetResetPasswordOperation() {
  const [state, setState] = useState({
    isLoading: false,
    isTokenValid: false,
    isChecked: false,
  })

  const { toastMessage } = useToastify()

  const sendResetPasswordMail = async (email_id) => {
    try {
      setState({ ...state, isLoading: true })
      const data = {
        email: email_id.toLowerCase(),
      }
      const res = await sendMail(forgotPasswordURL, data)
      let result = false
      if (res?.status === 200) {
        toastMessage('success', 'Mail sent successfully')
        result = true
      } else if (res?.status === 404) {
        toastMessage('error', 'Email not found')
      } else if (res?.status === 400) {
        toastMessage('error', 'Bad request.')
      } else {
        toastMessage('error', 'Invalid email.')
      }
      setState({ ...state, isLoading: false })
      return result
    } catch (e) {
      console.error(e)
    }
  }

  const validateToken = async (token) => {
    try {
      setState({ ...state, isLoading: true })
      const params = {
        token: token,
      }
      const res = await checkTokenValid(resetPasswordURL, params)
      if (res?.status === 200) {
        setState({ ...state, isTokenValid: true })
      } else if (res?.status === 403) {
        setState({ ...state, isTokenValid: false })
        toastMessage('error', 'Token is invalid')
      } else if (res?.status === 400) {
        setState({ ...state, isTokenValid: false })
        toastMessage('error', 'Bad request.')
      } else {
        setState({ ...state, isTokenValid: false })
        toastMessage('error', 'Invalid request.')
      }
      setState({ ...state, isLoading: false })
      setState({ ...state, isChecked: true })
      return res
    } catch (e) {
      console.error(e)
    }
  }

  const changePassword = async (password, token) => {
    try {
      setState({ ...state, isLoading: true })
      const data = {
        token: token,
        password: password,
      }
      const res = await setNewPassword(resetPasswordURL, data)
      if (res?.status === 200) {
        toastMessage('success', 'Password changed successfully')
      } else if (res?.status === 403) {
        toastMessage('error', 'Token is invalid')
        setState({ ...state, isLoading: false })
        return false
      } else if (res?.status === 404) {
        toastMessage('error', 'Email not found')
        setState({ ...state, isLoading: false })
        return false
      } else if (res?.status === 400) {
        toastMessage('error', 'Bad request.')
        setState({ ...state, isLoading: false })
        return false
      } else {
        toastMessage('error', 'Invalid email.')
        setState({ ...state, isLoading: false })
        return false
      }
      setState({ ...state, isLoading: false })
      return true
    } catch (e) {
      console.error(e)
    }
  }

  return {
    state,
    sendResetPasswordMail,
    validateToken,
    changePassword,
  }
}

export default useGetResetPasswordOperation

async function checkTokenValid(url, params) {
  const response = await Api.get(url, params, null)
  if (response?.status === 200) {
    return response
  }
  return response.response
}

async function sendMail(url, data) {
  const response = await Api.post(url, data)
  if (response?.status === 200) {
    return response
  }
  return response.response
}

async function setNewPassword(url, data) {
  const response = await Api.post(url, data)
  if (response?.status === 200) {
    return response
  }
  return response.response
}
